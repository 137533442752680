import { decreaseDateByHours, localTimeDate } from '@/composables/dateFormat.js'
import {computed} from "vue"
import { useStore } from 'vuex'


export function useTransferForm() {
    
    const store = useStore()
    
    // Static Variables
    const defaultSelectedSeats = [
        {
            name: 'Baby seat',
            ages:'',
            qty: 0
        },
        {
            name: 'Child seat',
            ages:'',
            qty:0
        },
        {
            name: 'Booster seat',
            ages:'',
            qty:0
        }
    ];

    const allowedLocations = {
        "Albufeira":"Albufeira",
        "Alcácer do Sal":"Alcácer do Sal",
        "Alcobaça":"Alcobaça",
        "Alcochete":"Alcochete",
        "Aldeia do Meco":"Aldeia do Meco",
        "Alenquer":"Alenquer",
        "Aljezur":"Aljezur",
        "Almancil":"Almancil",
        "Alvor":"Alvor",
        "Amarante":"Amarante",
        "Armação de pera":"Armação de pera",
        "Aroeira":"Aroeira",
        "Aveiro":"Aveiro",
        "Azeitão":"Azeitão",
        "Badajoz":"Badajoz",
        "Barcelona":"Barcelona",
        "Barcelos":"Barcelos",
        "Batalha":"Batalha",
        "Beja":"Beja",
        "Bom Sucesso Resort":"Bom Sucesso Resort",
        "Braga":"Braga",
        "Bussaco":"Bussaco",
        "Cabo da Roca":"Cabo da Roca",
        "Cáceres":"Cáceres",
        "Caldas da Rainha":"Caldas da Rainha",
        "Carcavelos":"Carcavelos",
        "Carvoeiro":"Carvoeiro",
        "Cascais":"Cascais",
        "Castelo Branco":"Castelo Branco",
        "Charneca da Caparica":"Charneca da Caparica",
        "Chaves":"Chaves",
        "Coimbra":"Coimbra",
        "Colares":"Colares",
        "Comporta":"Comporta",
        "Corunha":"Corunha",
        "Costa da Caparica":"Costa da Caparica",
        "Covilhã":"Covilhã",
        "Dolce CampoReal":"Dolce CampoReal",
        "Ericeira":"Ericeira",
        "Espinho":"Espinho",
        "Esposende":"Esposende",
        "Estoril":"Estoril",
        "Évora":"Évora",
        "Faro":"Faro",
        "Fátima":"Fátima",
        "Figueira da Foz":"Figueira da Foz",
        "Foz do Arelho":"Foz do Arelho",
        "Gerês":"Gerês",
        "Guarda":"Guarda",
        "Guimarães":"Guimarães",
        "Guincho":"Guincho",
        "Idanha-a-Nova":"Idanha-a-Nova",
        "Isla Canela":"Isla Canela",
        "Isla Cristina":"Isla Cristina",
        "Lagoa":"Lagoa",
        "Lagos":"Lagos",
        "Lamego":"Lamego",
        "Leiria":"Leiria",
        "Lisbon":"Lisbon",
        "Loulé":"Loulé",
        "Madrid":"Madrid",
        "Mafra":"Mafra",
        "Melides":"Melides",
        "Mértola":"Mértola",
        "Monte Gordo":"Monte Gordo",
        "Montijo":"Montijo",
        "Nazaré":"Nazaré",
        "Óbidos":"Óbidos",
        "Oeiras":"Oeiras",
        "Olhão":"Olhão",
        "Olhos de Água":"Olhos de Água",
        "Paço de Arcos":"Paço de Arcos",
        "Palmela":"Palmela",
        "Parede":"Parede",
        "Penafiel":"Penafiel",
        "Penha Longa Resort":"Penha Longa Resort",
        "Peniche":"Peniche",
        "Pinhão":"Pinhão",
        "Ponte de Lima":"Ponte de Lima",
        "Porches":"Porches",
        "Portalegre":"Portalegre",
        "Portimão":"Portimão",
        "Porto":"Porto",
        "Porto Covo":"Porto Covo",
        "Póvoa do Varzim":"Póvoa do Varzim",
        "Praia da Falésia":"Praia da Falésia",
        "Praia da Luz":"Praia da Luz",
        "Praia del Rey":"Praia del Rey",
        "Praia Grande":"Praia Grande",
        "Quarteira":"Quarteira",
        "Quinta da Marinha":"Quinta da Marinha",
        "Quinta da Ria Golf":"Quinta da Ria Golf",
        "Quinta do Lago":"Quinta do Lago",
        "Régua":"Régua",
        "Sagres":"Sagres",
        "Salamanca":"Salamanca",
        "Santa Cruz":"Santa Cruz",
        "Santarém":"Santarém",
        "Santa Maria da Feira":"Santa Maria da Feira",
        "Santiago de Compostela":"Santiago de Compostela",
        "Santo Estevão Golf Resort":"Santo Estevão Golf Resort",
        "S. João da Madeira":"S. João da Madeira",
        "São Martinho do Porto":"São Martinho do Porto",
        "Seixal":"Seixal",
        "Sesimbra":"Sesimbra",
        "Setúbal":"Setúbal",
        "Sevilha":"Sevilha",
        "Sines":"Sines",
        "Sintra":"Sintra",
        "Tavira":"Tavira",
        "Tomar":"Tomar",
        "Torres Vedras":"Torres Vedras",
        "Tróia":"Tróia",
        "Valença do Minho":"Valença do Minho",
        "Vendas Novas":"Vendas Novas",
        "Viana do Castelo":"Viana do Castelo",
        "Vidago":"Vidago",
        "Vigo":"Vigo",
        "Vila Nova de Gaia":"Vila Nova de Gaia",
        "Vila Nova de Milfontes":"Vila Nova de Milfontes",
        "Vila Real":"Vila Real",
        "Vila Real de Santo António":"Vila Real de Santo António",
        "Vilamoura":"Vilamoura",
        "Viseu":"Viseu",
        "Zambujeira do Mar":"Zambujeira do Mar"
    }


    // Computed
    const cities = computed(() => [...Object.values(allowedLocations), "Lisboa"])

    // Methods
    const retrieveUrlParamsByPurchaseNotes = (purchaseNotes) => {
        let urlQuery = {}

        if(purchaseNotes.dropOffLocation) {
            urlQuery.dropOffLocation = purchaseNotes.dropOffLocation
        }

        if(purchaseNotes.pickupLocation) {
            urlQuery.pickupLocation = purchaseNotes.pickupLocation
        }
        
        if(purchaseNotes.pickupTime) {
            urlQuery.pickupTime = purchaseNotes.pickupTime
        }
        
        if(purchaseNotes.pickupDate) {
            urlQuery.pickupDate = purchaseNotes.pickupDate
        }
    
        if(purchaseNotes.pickupLocationMapsId) {
            urlQuery.pickupLocationMapsId = purchaseNotes.pickupLocationMapsId
        }

        if(purchaseNotes.pickupLocationMapsDistrict) {
            urlQuery.pickupLocationMapsDistrict = purchaseNotes.pickupLocationMapsDistrict
        }
    
        if(purchaseNotes.dropOffLocationMapsId) {
            urlQuery.dropOffLocationMapsId = purchaseNotes.dropOffLocationMapsId
        }

        if(purchaseNotes.dropOffLocationMapsDistrict) {
            urlQuery.dropOffLocationMapsDistrict = purchaseNotes.dropOffLocationMapsDistrict
        }
    
        if(purchaseNotes.isRoundtrip) {
            urlQuery.isRoundtrip = purchaseNotes.isRoundtrip
        }
    
        if(purchaseNotes.numberOfPassengers) {
            urlQuery.numberOfPassengers = purchaseNotes.numberOfPassengers
        }
    
        if(purchaseNotes.selectedSeats[0].qty > 0) {
            urlQuery.babySeats = purchaseNotes.selectedSeats[0].qty
        }
    
        if(purchaseNotes.selectedSeats[1].qty > 0) {
            urlQuery.childSeats = purchaseNotes.selectedSeats[1].qty
        }
    
        if(purchaseNotes.selectedSeats[2].qty > 0) {
            urlQuery.boosterSeats = purchaseNotes.selectedSeats[2].qty
        }
    
        if(purchaseNotes.luggagePieces > 0) {
            urlQuery.luggagePieces = purchaseNotes.luggagePieces
        }
    
        if(purchaseNotes.selectedLanguage) {
            urlQuery.language = purchaseNotes.selectedLanguage
        }
    
        if(purchaseNotes.returnPickupDate) {
            urlQuery.returnPickupDate = purchaseNotes.returnPickupDate
        }
    
        if(purchaseNotes.returnPickupTime) {
            urlQuery.returnPickupTime = purchaseNotes.returnPickupTime
        }

        if(purchaseNotes.pointOfInterest) {
            urlQuery.pointOfInterest = purchaseNotes.pointOfInterest
        }

        return urlQuery;
    }

    const calcKidsSeatsValue = (vehicle, selectedSeats) => {
        let value = 0
        value += Number(vehicle.baby_seats_value) * selectedSeats[0].qty
        value += Number(vehicle.child_seats_value) * selectedSeats[1].qty
        value += Number(vehicle.booster_seats_value) * selectedSeats[2].qty
        return value
    }

    const calcPrice = (transferRoute, purchaseNotes) => {
        let value = 0
        let extraKidSeats = calcKidsSeatsValue(transferRoute.vehicle, purchaseNotes.selectedSeats)
        if(purchaseNotes.isRoundTrip) {
            value = Number(transferRoute.roundback_value) + (extraKidSeats * 2)
        } else {
            value = Number(transferRoute.value) + extraKidSeats;
        }
        return value
    }

    const generateCancelationPolicy = (service, purchaseNotes) => {
        let response = "Free cancellation not available"
        
        if (
            service && service.vehicle &&
            service.vehicle.free_cancelation && service.vehicle.free_cancelation_policy > 0
        ) {
            let pickUpDateTime = purchaseNotes.pickupDate + " " + purchaseNotes.pickupTime
            let freeCancelationDate = decreaseDateByHours(pickUpDateTime, service.vehicle.free_cancelation_policy)

            if (new Date(freeCancelationDate) >= new Date()) {
                response = "Free cancellation before " + localTimeDate(freeCancelationDate)
            }
        }
        
        return response
    }

    function arrangeMapsResponse(locations) {
        let responseObj = {}
        for(let location of locations) {
            for(let type of location.types) {
                if(/^administrative_area_level_\d$/.test(type) || type === "locality") {
                    responseObj[type] = location.short_name
                }
            }
        }
        return responseObj
    }

    const fishDistrict = (locations) => {
        let arrangedLocations = arrangeMapsResponse(locations)

        // validate locality first
        if(arrangedLocations.locality && cities.value.includes(arrangedLocations.locality)) {
            return fishedDistrictFormat(arrangedLocations.locality)
        }

        // validate other locations
        for(let locationKey in arrangedLocations) {
            if (cities.value.includes(arrangedLocations[locationKey]) && locationKey !== "locality") {
                fishedDistrictFormat(arrangedLocations[locationKey])
            }
        }

        // in case of nothing found return administrative_area_level_1
        return arrangedLocations.administrative_area_level_1
            ? fishedDistrictFormat(arrangedLocations.administrative_area_level_1)
            : null
    }

    const fishedDistrictFormat = (fishedDistrict) => {
        return fishedDistrict.replace(" District", "")
            .replace("Lisboa", "Lisbon")
            .replace("lisboa", "Lisbon")
            .replace("lisbon", "Lisbon")
    }

    return { defaultSelectedSeats, retrieveUrlParamsByPurchaseNotes, calcPrice, generateCancelationPolicy, fishDistrict, allowedLocations }
}