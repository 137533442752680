<script setup>
import { defineEmits, defineProps, ref, watch, nextTick } from 'vue';
import SvgComponent from '../SvgComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

const emit = defineEmits(['update:modelValue', 'performSearch']);

// Props
const props = defineProps({
    modelValue: {type: String, default: ""},
    autocompleteSuggestion: {type: String, default:null},
    placeholder: { type: String, default: 'SEARCH HERE' }
})

// Reactive variables
const text = ref(props.modelValue)
const inputElement = ref(null)

// Methods
function onInput(event){
    if(
        props.autocompleteSuggestion && 
        event.data && 
        props.autocompleteSuggestion.toLowerCase().startsWith(text.value.toLowerCase())
    ) {
        displayAutoComplete()
    }
    emit('update:modelValue', text.value)
    
}

function displayAutoComplete() {
    let currentTextLength = text.value.length
    text.value = text.value + props.autocompleteSuggestion.substring(currentTextLength, props.autocompleteSuggestion.length).toLocaleLowerCase()
    nextTick(() => {
        inputElement.value.setSelectionRange(currentTextLength, props.autocompleteSuggestion.length);
    })
}

function onClickSearchIcon() {
    emit("performSearch")
}

function onPressEnter() {
    emit("performSearch")
}
</script>

<template>
    <div class="search-input-wrapper">
        <input :placeholder="placeholder" @input="onInput" v-model="text" ref="inputElement" @keyup.enter="onPressEnter()"/>

        <ButtonComponent @click="onClickSearchIcon()" class="btn-search" color="transparent" simple :size="{ width: '20px', height: '21px' }">
            <SvgComponent name="IconMagnifying" />
        </ButtonComponent>
    </div>
</template>

<style lang="scss" scoped>
    .search-input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-width: 328px;
        width: calc(52% - 168px);

        input {
            width: 100%;
            height: 47px;
            border: none;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.10);
            border-radius: 12px;
            padding: 16px;
            color: #162659;
            font-family: Montserrat;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            outline: none;
        }

        .btn-search {
            position: absolute;
            right: 16px;
        }

        &.is-open{
            z-index: 6;
        }
    }
</style>