<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
// import router from '@/router'
import { useRouter } from 'vue-router'

import SearchInputComponent from '@/components/form/SearchInputComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import CardComponent from '@/components/CardComponent.vue'

//Variables
const store = useStore()
const searchString = computed({
    get: () => store.getters["company/getActiveVehiclesSearchString"],
    set: (value) => store.dispatch("company/setActiveServicesSearchString", value)
})
const isDeleteButtonEnabled = computed(() => store.getters["company/getSelectedVehicles"].length > 0)
const isDisableButtonEnabled = computed(() => store.getters["company/getSelectedVehicles"].length > 0)
const router = useRouter()

// Methods
function performSearch() {
    store.dispatch("company/applyVehicleFilter")
}

function onClickDisableButton() {
    store.dispatch('event/showWarningModal', {
        title: 'Warning',
        htmlCopy: '<p>The selected vehicles will be permanently disabled.<br><strong>Do you wish to proceed?</strong></p>',
        buttons: [
            {
                copy: "Yes",
                action: performDisable,
                htmlAttributes: {"color": "primary", "style": "min-width:84px;"},
            },
            {
                copy: "No",
                action: null,
                htmlAttributes: {"bordered": true, "class": "btn-decline", "style": "min-width:84px;"}
            }
        ],
        exitAction:null
    })
}

function onClickDeleteButton() {
    store.dispatch('event/showWarningModal', {
        title: 'Warning',
        htmlCopy: '<p>The selected services will be permanently deleted.<br><strong>Do you wish to proceed?</strong></p>',
        buttons: [
            {
                copy: "Yes",
                action: performDelete,
                htmlAttributes: {"color": "primary", "style": "min-width:84px;"},
            },
            {
                copy: "No",
                action: null,
                htmlAttributes: {"bordered": true, "class": "btn-decline", "style": "min-width:84px;"}
            }
        ],
        exitAction:null
    })
}

async function performDisable() {
    await store.dispatch("company/updateVehicles", {ids: store.getters["company/getSelectedVehicles"], vehicle_status: "inactive"})
    router.go(router.currentRoute)
}

async function performDelete() {
    await store.dispatch("company/updateVehicles", {ids: store.getters["company/getSelectedVehicles"], delete: true})
    router.go(router.currentRoute)
}

// Computed
const isAuthenticated = computed(() => store.getters['user/getIsLoggedIn'])
const canShowDisableButton = computed(() => router.currentRoute.value.path === "/dashboard/vehicles/preview")
const canShowDeleteButton = computed(() => router.currentRoute.value.path === "/dashboard/vehicles/preview/inactive")

// Callbacks Lifecycle
onMounted(() => {
    if (!isAuthenticated.value) {
        router.push({
            "name": "login"
        })
    }
})
</script>

<template>
    <div class="salutation">
        <h1>Transfers</h1>
        <p>Manage your vehicles</p>
        <div class="d-flex flex-wrap filters-wrapper">
            <SearchInputComponent
                v-model="searchString"
                @performSearch="performSearch"
            />
            <div class="d-flex flex-wrap gap ml-auto">
                <router-link to="/dashboard/vehicles/new" custom v-slot="{ navigate }"><ButtonComponent color="primary" @click="navigate">ADD VEHICLE</ButtonComponent></router-link>
                <ButtonComponent v-if="canShowDeleteButton" :disabled="!isDeleteButtonEnabled" color="danger" @click="onClickDeleteButton()">DELETE</ButtonComponent>
                <ButtonComponent v-if="canShowDisableButton" :disabled="!isDisableButtonEnabled" color="danger" @click="onClickDisableButton()">DISABLE</ButtonComponent>
            </div>
        </div>
        <div class="row d-flex direction-column">
            <div class="d-flex tabs-wrapper">
                <router-link to="/dashboard/vehicles/preview" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">ACTIVE</ButtonComponent></router-link>
                <router-link to="/dashboard/vehicles/preview/inactive" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">INACTIVE</ButtonComponent></router-link>
                <!-- <router-link to="/dashboard/services/preview/promoted" custom v-slot="{ isExactActive, navigate }"><ButtonComponent :color="isExactActive ? 'light' : 'light-primary'" @click="navigate" :class="{active: isExactActive}">PROMOTED</ButtonComponent></router-link> -->
            </div>
            <CardComponent class="vehicles-stage">
                <router-view />
            </CardComponent>
        </div>
    </div>
    
</template>

<style lang="scss" scoped>
.row {
    flex-direction: column;
    margin-bottom: 20px;

    &:not(.direction-column) {
        @media screen and (min-width: 1200px) {
            flex-direction: row;
        }
    }
}
.filters-wrapper {
    gap: 15px;
    align-items: center;
    margin-top: 56px;
    margin-bottom: 31px;
    justify-content: space-between;

    button {
        min-width: 115px;
        padding: 15.5px;
    }
    .btn-dropdown {
        border-color: var(--primary-icon-color-30);
        max-height: 47px;

        span {
            margin-left: 15.47px;
            margin-right: 11.12px;
        }
    }
}

.primary {
    font-size: 11px;
}

.tabs-wrapper {
    margin-bottom: -10px;
    gap: 2.1px;

    button {
        min-width: 102px;
        position: relative;
        padding-bottom: 25.5px;
        font-weight: 500;

        border-radius: 0;

        &:first-child {
            border-radius: 12px  0 0 0;
            box-shadow: -3px -3px 6px -3px rgba(0, 0, 0, 0.20);
        }

        &:last-child {
            border-radius: 0 12px 0 0;
        }

        &:not(.active):hover {
            background-color: var(--light-secondary-color);
        }

        &.active {
            z-index: 1;
            font-weight: 700;
        }
    }
}
</style>