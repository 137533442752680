import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueEasyLightbox from 'vue-easy-lightbox'
import ToastPlugin from 'vue-toast-notification'
import VueTelInput from 'vue3-tel-input'
import 'vue-advanced-cropper/dist/style.css';
import 'vue3-tel-input/dist/vue3-tel-input.css'
import '@/assets/styles/app.scss'
import VueGtag from 'vue-gtag'
import { createHead } from '@unhead/vue'

const head = createHead()
const app = createApp(App).use(store).use(router).use(ToastPlugin).use(VueTelInput).use(VueEasyLightbox).use(head)
if(process.env.NODE_ENV === 'production') {
    const gtagConfig = {
        config: { id: "G-VCPKPB0GJD" }
    }
    app.use(VueGtag, gtagConfig)
}
app.mount('#app')

function adjustHeight() {
    document.getElementsByTagName('body')[0].style.height = `${window.innerHeight}px`
}

adjustHeight();
window.addEventListener('resize', adjustHeight);
window.addEventListener('orientationchange', adjustHeight);