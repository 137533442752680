import ApiManager from '@/helpers/ApiManager.js'

function setupServicesListingParams(payload) {
    let params = {
        page:1,
        "order-by": "most-recent"
    }

    for(let key in payload) {
        if(payload[key]) {
            params[key] = payload[key]
        }
    }

    return paramsObjectToString(params)
}

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        paramsArr.push(key + "=" + paramsObject[key])
    }
    return paramsArr.join("&");
}

const state = {
    seeCountry: "pt",
    sponsoredServices: null,
    servicesData: null,
    serviceCategories: null,
    platformCountries: null,
    currentService: null,
    currentCompany: null,
    serviceLocations: null,
    filtersCounts: null,
    firstLoadFiltersCounts:null,
    filterablePriceRange: {
        "max_value": null,
        "min_value": null
    },
    transfersPickupLocations: null,
    transfersDropoffLocations: null,
    numberOfSeatsRange: {
        "max_value": null,
        "min_value": null
    },
    currentTransferRoute: null,
    transfersExist: null,
    pointOfInterestMapsIds: {
        "Lisbon Airport": "ChIJgwHZFkYyGQ0RRm0DWD6lZgo",
        "Lisbon Cruise Port": "ChIJ55637nQ0GQ0R0peLkF79Z1Q",
        "Oriente Train Station": "ChIJCcgU0YcxGQ0RPnRwQXl7K74",
        "Santa Apolónia Train Station": "ChIJO41ppAo0GQ0RCSkBTGuQ1Uc",
        "Porto Airport": "ChIJrStKYWRvJA0R0TlgbYpXGhU",
        "Porto Campanha Train Station": "ChIJCc_gS5BkJA0RRcLV4YIqLRU",
        "Faro Airport": "ChIJm5mnTbBSBQ0RRztnYx5VRCE",
    },
    pointOfInterestMapsDistricts: {
        "Lisbon Airport": "Lisbon",
        "Lisbon Cruise Port": "Lisbon",
        "Oriente Train Station": "Lisbon",
        "Santa Apolónia Train Station": "Lisbon",
        "Porto Airport": "Porto",
        "Porto Campanha Train Station": "Porto",
        "Faro Airport": "Faro"
    },
    worldCountries: {
        "AF": "Afghanistan",
        "AL": "Albania",
        "DZ": "Algeria",
        "AS": "American Samoa",
        "AD": "Andorra",
        "AO": "Angola",
        "AI": "Anguilla",
        "AQ": "Antarctica",
        "AG": "Antigua and Barbuda",
        "AR": "Argentina",
        "AM": "Armenia",
        "AW": "Aruba",
        "AU": "Australia",
        "AT": "Austria",
        "AZ": "Azerbaijan",
        "BS": "Bahamas (the)",
        "BH": "Bahrain",
        "BD": "Bangladesh",
        "BB": "Barbados",
        "BY": "Belarus",
        "BE": "Belgium",
        "BZ": "Belize",
        "BJ": "Benin",
        "BM": "Bermuda",
        "BT": "Bhutan",
        "BO": "Bolivia (Plurinational State of)",
        "BQ": "Bonaire, Sint Eustatius and Saba",
        "BA": "Bosnia and Herzegovina",
        "BW": "Botswana",
        "BV": "Bouvet Island",
        "BR": "Brazil",
        "IO": "British Indian Ocean Territory (the)",
        "BN": "Brunei Darussalam",
        "BG": "Bulgaria",
        "BF": "Burkina Faso",
        "BI": "Burundi",
        "CV": "Cabo Verde",
        "KH": "Cambodia",
        "CM": "Cameroon",
        "CA": "Canada",
        "KY": "Cayman Islands (the)",
        "CF": "Central African Republic (the)",
        "TD": "Chad",
        "CL": "Chile",
        "CN": "China",
        "CX": "Christmas Island",
        "CC": "Cocos (Keeling) Islands (the)",
        "CO": "Colombia",
        "KM": "Comoros (the)",
        "CD": "Congo (the Democratic Republic of the)",
        "CG": "Congo (the)",
        "CK": "Cook Islands (the)",
        "CR": "Costa Rica",
        "HR": "Croatia",
        "CU": "Cuba",
        "CW": "Curaçao",
        "CY": "Cyprus",
        "CZ": "Czechia",
        "CI": "Côte d'Ivoire",
        "DK": "Denmark",
        "DJ": "Djibouti",
        "DM": "Dominica",
        "DO": "Dominican Republic (the)",
        "EC": "Ecuador",
        "EG": "Egypt",
        "SV": "El Salvador",
        "GQ": "Equatorial Guinea",
        "ER": "Eritrea",
        "EE": "Estonia",
        "SZ": "Eswatini",
        "ET": "Ethiopia",
        "FK": "Falkland Islands (the) [Malvinas]",
        "FO": "Faroe Islands (the)",
        "FJ": "Fiji",
        "FI": "Finland",
        "FR": "France",
        "GF": "French Guiana",
        "PF": "French Polynesia",
        "TF": "French Southern Territories (the)",
        "GA": "Gabon",
        "GM": "Gambia (the)",
        "GE": "Georgia",
        "DE": "Germany",
        "GH": "Ghana",
        "GI": "Gibraltar",
        "GR": "Greece",
        "GL": "Greenland",
        "GD": "Grenada",
        "GP": "Guadeloupe",
        "GU": "Guam",
        "GT": "Guatemala",
        "GG": "Guernsey",
        "GN": "Guinea",
        "GW": "Guinea-Bissau",
        "GY": "Guyana",
        "HT": "Haiti",
        "HM": "Heard Island and McDonald Islands",
        "VA": "Holy See (the)",
        "HN": "Honduras",
        "HK": "Hong Kong",
        "HU": "Hungary",
        "IS": "Iceland",
        "IN": "India",
        "ID": "Indonesia",
        "IR": "Iran (Islamic Republic of)",
        "IQ": "Iraq",
        "IE": "Ireland",
        "IM": "Isle of Man",
        "IL": "Israel",
        "IT": "Italy",
        "JM": "Jamaica",
        "JP": "Japan",
        "JE": "Jersey",
        "JO": "Jordan",
        "KZ": "Kazakhstan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "KP": "Korea (the Democratic People's Republic of)",
        "KR": "Korea (the Republic of)",
        "KW": "Kuwait",
        "KG": "Kyrgyzstan",
        "LA": "Lao People's Democratic Republic (the)",
        "LV": "Latvia",
        "LB": "Lebanon",
        "LS": "Lesotho",
        "LR": "Liberia",
        "LY": "Libya",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "MO": "Macao",
        "MG": "Madagascar",
        "MW": "Malawi",
        "MY": "Malaysia",
        "MV": "Maldives",
        "ML": "Mali",
        "MT": "Malta",
        "MH": "Marshall Islands (the)",
        "MQ": "Martinique",
        "MR": "Mauritania",
        "MU": "Mauritius",
        "YT": "Mayotte",
        "MX": "Mexico",
        "FM": "Micronesia (Federated States of)",
        "MD": "Moldova (the Republic of)",
        "MC": "Monaco",
        "MN": "Mongolia",
        "ME": "Montenegro",
        "MS": "Montserrat",
        "MA": "Morocco",
        "MZ": "Mozambique",
        "MM": "Myanmar",
        "NA": "Namibia",
        "NR": "Nauru",
        "NP": "Nepal",
        "NL": "Netherlands (the)",
        "NC": "New Caledonia",
        "NZ": "New Zealand",
        "NI": "Nicaragua",
        "NE": "Niger (the)",
        "NG": "Nigeria",
        "NU": "Niue",
        "NF": "Norfolk Island",
        "MP": "Northern Mariana Islands (the)",
        "NO": "Norway",
        "OM": "Oman",
        "PK": "Pakistan",
        "PW": "Palau",
        "PS": "Palestine, State of",
        "PA": "Panama",
        "PG": "Papua New Guinea",
        "PY": "Paraguay",
        "PE": "Peru",
        "PH": "Philippines (the)",
        "PN": "Pitcairn",
        "PL": "Poland",
        "PT": "Portugal",
        "PR": "Puerto Rico",
        "QA": "Qatar",
        "MK": "Republic of North Macedonia",
        "RO": "Romania",
        "RU": "Russian Federation (the)",
        "RW": "Rwanda",
        "RE": "Réunion",
        "BL": "Saint Barthélemy",
        "SH": "Saint Helena, Ascension and Tristan da Cunha",
        "KN": "Saint Kitts and Nevis",
        "LC": "Saint Lucia",
        "MF": "Saint Martin (French part)",
        "PM": "Saint Pierre and Miquelon",
        "VC": "Saint Vincent and the Grenadines",
        "WS": "Samoa",
        "SM": "San Marino",
        "ST": "Sao Tome and Principe",
        "SA": "Saudi Arabia",
        "SN": "Senegal",
        "RS": "Serbia",
        "SC": "Seychelles",
        "SL": "Sierra Leone",
        "SG": "Singapore",
        "SX": "Sint Maarten (Dutch part)",
        "SK": "Slovakia",
        "SI": "Slovenia",
        "SB": "Solomon Islands",
        "SO": "Somalia",
        "ZA": "South Africa",
        "GS": "South Georgia and the South Sandwich Islands",
        "SS": "South Sudan",
        "ES": "Spain",
        "LK": "Sri Lanka",
        "SD": "Sudan (the)",
        "SR": "Suriname",
        "SJ": "Svalbard and Jan Mayen",
        "SE": "Sweden",
        "CH": "Switzerland",
        "SY": "Syrian Arab Republic",
        "TW": "Taiwan (Province of China)",
        "TJ": "Tajikistan",
        "TZ": "Tanzania, United Republic of",
        "TH": "Thailand",
        "TL": "Timor-Leste",
        "TG": "Togo",
        "TK": "Tokelau",
        "TO": "Tonga",
        "TT": "Trinidad and Tobago",
        "TN": "Tunisia",
        "TR": "Turkey",
        "TM": "Turkmenistan",
        "TC": "Turks and Caicos Islands (the)",
        "TV": "Tuvalu",
        "UG": "Uganda",
        "UA": "Ukraine",
        "AE": "United Arab Emirates (the)",
        "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
        "UM": "United States Minor Outlying Islands (the)",
        "US": "United States of America (the)",
        "UY": "Uruguay",
        "UZ": "Uzbekistan",
        "VU": "Vanuatu",
        "VE": "Venezuela (Bolivarian Republic of)",
        "VN": "Viet Nam",
        "VG": "Virgin Islands (British)",
        "VI": "Virgin Islands (U.S.)",
        "WF": "Wallis and Futuna",
        "EH": "Western Sahara",
        "YE": "Yemen",
        "ZM": "Zambia",
        "ZW": "Zimbabwe",
        "AX": "Åland Islands"
    },
    latestComments: null,
    eventsData: null,
    eventLocations: null,
    eventVenues: null,
    eventCategories: null,
    serviceCategoryOptions: null,
}

const getters = {
    getSponsoredServices: (state) => state.sponsoredServices,
    getServicesData: (state) => state.servicesData,
    getServiceCategories: (state) => state.serviceCategories,
    getCities: (state) => state.platformCountries ? state.platformCountries[state.seeCountry].cities : [],
    getAllServices: (state) => [...(state.sponsoredServices ?? []), ...(state.servicesData?.data ?? [])],
    getCurrentService: (state, getters) => slug => {
        if (state.currentService?.id === slug) {
            return state.currentService
        } else if (state.currentService?.service_slug === slug) {
            return state.currentService
        } 
        return {}
    },
    getCurrentCompany: state => state.currentCompany,
    getServiceLocations: state => state.serviceLocations,
    getFiltersCounts: state => state.filtersCounts,
    getFirstLoadFiltersCounts: state => state.firstLoadFiltersCounts,
    getFilterablePriceRange: state => state.filterablePriceRange,
    getNumberOfSeatsRange: state => state.numberOfSeatsRange,
    getCurrentTransferRoute: state => state.currentTransferRoute,
    getTransfersExist: state => state.transfersExist,
    getPointOfInterestMapsIds: state => state.pointOfInterestMapsIds,
    getPointOfInterestMapsDistricts: state => state.pointOfInterestMapsDistricts,
    getMapsIdsPointsOfInterest: state => Object.fromEntries(
        Object.entries(
            state.pointOfInterestMapsIds
        ).map(([key, value]) => [value, key])
    ),
    getPointOfInterest: state => Object.fromEntries(
        Object.entries(
            state.pointOfInterestMapsIds
        ).map(([key, value]) => [key, key])
    ),
    getWorldCountries: state => state.worldCountries,
    getPlatformCountries: state => state.platformCountries,
    getLatestComments: state => state.latestComments,
    getEventsData: state => state.eventsData,
    getEventLocations: (state) => {
        if (! state.eventLocations) return null

        let locations = {}
        for (let location of state.eventLocations) {
            if (location.name) {
                locations[location.name] = location.name
            }
        }
        
        return locations
    },
    getEventVenues: (state) => {
        if (! state.eventVenues) return null

        let venues = {}
        for (let venue of state.eventVenues) {
            if (venue.name) {
                venues[venue.name] = venue.name
            }
        }

        return venues
    },
    getEventCategories: (state) => {
        if (! state.eventCategories) return null

        let categories = {}
        for (let category of state.eventCategories) {
            if (category.name) {
                categories[category.name] = category.name
            }
        }

        return categories
    },
    getServiceCategoryOptions: (state) => state.serviceCategoryOptions,
}

const actions = {
    fetchSponsoredServices: async ({ commit }) => {
        let getAttempt = await ApiManager.get(`/api/services/sponsored`)
        if (getAttempt) {
            commit("SET_SPONSORED_SERVICES", getAttempt.data)
            return true
        }
        return false
    },
    fetchServices: async ({ commit }, payload) => {
        let url = "/api/services"
        if (payload?.url) {
            url = payload.url
            delete payload.url
        }

        let paramsString = setupServicesListingParams(payload)
        let getAttempt = await ApiManager.get(`${url}?${paramsString}`)
        if (getAttempt) {
            commit("SET_SERVICES", getAttempt.data)
            return true
        }
        return false
    },
    fetchFiltersCounts: async ({ commit, state }, payload) => {
        let url = "/api/services-count-filter-options"
        if(payload.url) {
            url = payload.url
            delete payload.url
        }

        let paramsString = setupServicesListingParams(payload)
        let getAttempt = await ApiManager.get(`${url}?${paramsString}`)
        if (getAttempt) {
            commit("SET_FILTERS_COUNTS", getAttempt.data)
            if (!state.firstLoadFiltersCounts) {
                commit("SET_FIRST_LOAD_FILTERS_COUNTS")
            }
            return true
        }
        return false
    },

    fetchFiltrablePriceRange: async ({ commit }, payload) => {

        let url = "api/services-filtrable-price-range"
        if(payload.url) {
            url = payload.url
            delete payload.url
        }

        let paramsString = setupServicesListingParams(payload)
        let getAttempt = await ApiManager.get(`${url}?${paramsString}`)
        if (getAttempt) {
            commit("SET_FILTERABLE_PRICE_RANGE", getAttempt.data)
            return true
        }
        return false
        
    },

    fetchNumberOfSeatsRange: async ({commit}, payload) => {

        let url = "api/transfers-filtrable-number-of-seats"
        if(payload.url) {
            url = payload.url
            delete payload.url
        }

        let paramsString = setupServicesListingParams(payload)
        let getAttempt = await ApiManager.get(`${url}?${paramsString}`)
        if (getAttempt) {
            commit("SET_NUMBER_OF_SEATS_RANGE", getAttempt.data)
            return true
        }
        return false

    },

    fetchServiceCategories: async ({ commit }) => {

        let getAttempt = await ApiManager.get(`/api/categories`)
        if (getAttempt) {
            commit("SET_SERVICE_CATEGORIES", getAttempt.data)
            return true
        }
        return false
    },

    fetchPlatformCountries: async ({ commit }) => {
        let getAttempt = await ApiManager.get(`/api/platformcountries`)
        if (getAttempt) {
            commit("SET_PLATFORM_COUNTRIES", getAttempt.data)
            return true
        }
        return false
    },

    getServiceBySlug: async ({ commit }, payload) => {
        let getAttempt = await ApiManager.get(`/api/services/${payload}`)
        if (getAttempt) {
            return commit("SET_CURRENT_SERVICE", getAttempt.data)
        }
        return false
    },

    getCompanyById: async ({commit}, payload) => {
        let getAttempt = await ApiManager.get(`/api/companies/${payload}`)
        if(getAttempt) {
            return commit("SET_CURRENT_COMPANY", getAttempt.data)
        }
        return false
    },

    fetchServiceLocations: async ({ commit }, payload) => {
        let getAttempt = await ApiManager.get(`/api/services/locations`)
        if(getAttempt) {
            return commit("SET_SERVICE_LOCATIONS", getAttempt.data)
        }
        return false
    },
    
    fetchCurrentTransferRoute: async({commit}, payload) => {
        let url = `/api/transfers/${payload.id}`
        delete payload.id
        let getAttempt = await ApiManager.get(url, {params: payload})
        if(getAttempt && getAttempt.data.status) {
            return commit("SET_CURRENT_TRANSFER_ROUTE", getAttempt.data.content)
        }
        return false
    },

    fetchTransfersExist: async({commit}, payload) => {
        let getAttempt = await ApiManager.get('/api/transfers-light-check-if-exists', {params: payload})
        if(getAttempt) {
            return commit("SET_TRANSFERS_EXIST", getAttempt.data)
        }
        return false
    },

    fetchLatestComments: async({commit}, payload) => {
        let getAttempt = await ApiManager.get('/api/latest-comments')
        if(getAttempt) {
            return commit("SET_LATEST_COMMENTS", getAttempt.data)
        }
        return false
    },

    fetchEvents: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`/api/bol-events?${paramsString}`)

        if (getAttempt) {
            commit("SET_EVENTS", getAttempt.data)
            return true
        }

        return false
    },

    fetchEventLocations: async ({ commit }, payload) => {
        const getAttempt = await ApiManager.get('/api/bol-locations')

        if (getAttempt) {
            commit("SET_EVENT_LOCATIONS", getAttempt.data)
            return true
        }

        return false
    },

    fetchEventVenues: async ({ commit }, payload) => {
        const getAttempt = await ApiManager.get('/api/bol-venues')

        if (getAttempt) {
            commit("SET_EVENT_VENUES", getAttempt.data)
            return true
        }

        return false
    },

    fetchEventCategories: async ({ commit }, payload) => {
        const getAttempt = await ApiManager.get('/api/bol-categories')

        if (getAttempt) {
            commit("SET_EVENT_CATEGORIES", getAttempt.data)
            return true
        }

        return false
    },
}

const mutations = {
    SET_SPONSORED_SERVICES: (state, services) => {
        state.sponsoredServices = services
    },
    SET_SERVICES: (state, services) => {
        state.servicesData = services
    },
    SET_SERVICE_CATEGORIES: (state, categories) => {
        let categoriesObject = {}
        for (let cat of categories) {
            categoriesObject[cat.id] = cat
        }
        state.serviceCategories = categoriesObject

        let categoryOptions = {}
        for (let category of categories) {
            categoryOptions[category.id] = category.category_name
        }

        state.serviceCategoryOptions = categoryOptions
    },
    SET_PLATFORM_COUNTRIES: (state, platformCountries) => {
        state.platformCountries = platformCountries
    },
    SET_CURRENT_SERVICE: (state, service) => {
        state.currentService = service
    },
    SET_CURRENT_COMPANY: (state, company) => {
        state.currentCompany = company
    },
    SET_SERVICE_LOCATIONS: (state, serverResponseLocations) => {
        let tempLocations = {}
        for (let item of serverResponseLocations) {
            tempLocations[item.location] = item.location
        }
        state.serviceLocations = tempLocations
    },
    SET_FILTERS_COUNTS: (state, filtersCounts) => {
        let formatedFiltersCounts = {}
        for (let filterType in filtersCounts) {
            formatedFiltersCounts[filterType] = {}
            
            for (let theCountArr of filtersCounts[filterType]) {
                formatedFiltersCounts[filterType][theCountArr["filter_value"]] = theCountArr["count"]
            }
        }
        state.filtersCounts = formatedFiltersCounts
    },
    SET_FIRST_LOAD_FILTERS_COUNTS: (state) => {
        state.firstLoadFiltersCounts = structuredClone(state.filtersCounts)
    },
    SET_FILTERABLE_PRICE_RANGE: (state, filterablePriceRange) => {
        state.filterablePriceRange = filterablePriceRange
    },
    SET_NUMBER_OF_SEATS_RANGE: (state, numberOfSeatsRange) => {
        state.numberOfSeatsRange = numberOfSeatsRange
    },
    SET_CURRENT_TRANSFER_ROUTE: (state, currentTransferRoute) => {
        state.currentTransferRoute = currentTransferRoute
    },
    REPLACE_CURRENT_TRANSFER_ROUTE: (state, newTransferRoute) => {
        newTransferRoute.vehicle = state.currentTransferRoute.vehicle
        state.currentTransferRoute = newTransferRoute
    },
    SET_TRANSFERS_EXIST: (state, transfersExist) => {
        state.transfersExist = transfersExist
    },
    SET_LATEST_COMMENTS: (state, latestComments) => {
        state.latestComments = latestComments
    },
    SET_EVENTS: (state, events) => {
        state.eventsData = events
    },
    SET_EVENT_LOCATIONS: (state, locations) => {
        state.eventLocations = locations
    },
    SET_EVENT_VENUES: (state, venues) => {
        state.eventVenues = venues
    },
    SET_EVENT_CATEGORIES: (state, categories) => {
        state.eventCategories = categories
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}