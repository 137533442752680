import { createStore } from 'vuex'
import company from './companyStore'
import user from './userStore'
import form from './formStore'
import event from './eventStore'
import frontend from './frontendStore'
import booking from './bookingStore'
import checkout from './checkoutStore'
import chat from './chatStore'
import superAdm from './superStore'
import sale from './saleStore'
import partner from './partnerStore'
import affiliate from './affiliateStore'
import client from './clientStore'
import service from './serviceStore'
import bookingItem from './bookingItemStore'
import dashSuper from './dashSuperStore'
import promoCode from './promoCodeStore'
import team from './teamStore'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    company,
    user,
    form,
    event,
    frontend,
    booking,
    checkout,
    chat,
    superAdm,
    sale,
    partner,
    affiliate,
    client,
    service,
    bookingItem,
    dashSuper,
    promoCode,
    team
  }
})
