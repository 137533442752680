import ApiManager from "@/helpers/ApiManager"

import { convertDateFormat, decreaseDateByHours } from '@/composables/dateFormat'

function calcFreeCancelationDate(service, purchaseNotes) {
    const serviceAnnotations = typeof service.annotations === "string"
        ? JSON.parse(service.annotations) 
        : service.annotations

    if (serviceAnnotations?.free_cancellation > 0 && serviceAnnotations?.cancellation_policy > 0) {
        return decreaseDateByHours(purchaseNotes.date, serviceAnnotations?.cancellation_policy)
    }

    return null
}

const normalizeBookingItems = (bookingItems) => {
    return bookingItems.map((item) => {
        const totalStars = item.booking.user.reviews.reduce((total, review) => total + review.stars, 0)
        const reviews = item.booking.user.reviews.length
        const stars = totalStars / reviews
        const bookingAnnotations = item.booking.annotations && typeof item.booking.annotations === 'string'
            ? JSON.parse(item.booking.annotations)
            : {}

        let avatar = null;
        if(item?.booking?.user && item.booking.user.avatar[0] && item.booking.user.avatar[0]?.file_name) {
            avatar = `${process.env.VUE_APP_API_URL}/storage/avatar/${item.booking.user.avatar[0].file_name}`
        }

        const itemAnnotations = typeof item.annotations === "string" 
            ? JSON.parse(item.annotations) 
            : item.annotations

        let response = {
            user: {
                id: item.booking.user.id,
                name: item.booking.user.first_name + ' ' + item.booking.user.last_name,
                birthDate: new Date(item.booking.user.profile.birthdate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
                avatar: avatar ?? require('@/assets/images/user_fallback_avatar.png'),
                createdAt: convertDateFormat(item.booking.user.created_at),
            },
            freeCancelation: calcFreeCancelationDate(item.unserialized_service, itemAnnotations),
            date: new Date(item.booking_date).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
            hour: new Date(item.booking_date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }) + 'h',
            eventDate: item.booking_date,
            rating: stars,
            createdAt: item.created_at,
            language: item.annotations?.selected_language ?? 'Portuguese',
            status: item.booking_item_status,
            details: typeof item.annotations === "string" ? JSON.parse(item.annotations) : item.annotations,
            value: item.value,
            id: item.id,
            serviceData: item.unserialized_service,
            notes: bookingAnnotations.notes ?? null,
            pendingHours: item.pending_hours,
        }

        response.service = null
        if (item.unserialized_service && item.unserialized_service.service) {
            response.service = item.unserialized_service.service
        } else if (item.service && item.service.service_name) {
            response.service = item.service.service_name
        }

        if (item.service && item.service.duration) {
            response.duration = item.service.duration
        }

        if (item.service && item.service.duration) {
            response.tours = item.service.duration
        }

        return response
    })
}

function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        if (paramsObject[key]) {
            paramsArr.push(key + "=" + paramsObject[key])
        }
    }

    return paramsArr.join("&");
}

const state = {
    newBookingItems: [],
    acceptedBookingItems: [],
    archivedBookingItems: [],
}

const getters = {
    getNewBookingItems: state => state.newBookingItems ?? [],
    getAcceptedBookingItems: state => state.acceptedBookingItems ?? [],
    getArchivedBookingItems: state => state.archivedBookingItems ?? [],
}

const actions = {
    fetchBookingItems: async ({ commit }, payload) => {
        const paramsString = paramsObjectToString({ page: 1, ...payload })
        const getAttempt = await ApiManager.get(`api/super/booking-items?${paramsString}`)

        if (getAttempt) {
            if (payload.status === 'new') {
                commit('SET_NEW_BOOKING_ITEMS', getAttempt.data.content.data)
            }

            if (payload.status === 'accepted') {
                commit('SET_ACCEPTED_BOOKING_ITEMS', getAttempt.data.content.data)
            }

            if (payload.status === 'archived') {
                commit('SET_ARCHIVED_BOOKING_ITEMS', getAttempt.data.content.data)
            }

            return true
        }

        return false
    },
}

const mutations = {
    SET_NEW_BOOKING_ITEMS: (state, payload) => {
        state.newBookingItems = [...normalizeBookingItems(payload)]
    },
    SET_ACCEPTED_BOOKING_ITEMS: (state, payload) => {
        state.acceptedBookingItems = [...normalizeBookingItems(payload)]
    },
    SET_ARCHIVED_BOOKING_ITEMS: (state, payload) => {
        state.archivedBookingItems = [...normalizeBookingItems(payload)]
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}