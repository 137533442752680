export function priceFormat(value, currencySymbol = "€") {
    let price = Number(value)
    if(Number.isInteger(price)) {
        return currencySymbol + price
    }
    return currencySymbol + price.toFixed(2)
}

export function paramsObjectToString(paramsObject) {
    let paramsArr = []
    for (let key in paramsObject) {
        paramsArr.push(key + "=" + paramsObject[key])
    }
    return paramsArr.join("&");
}

export function recursiveFindParentWithClassName(element, className) {
    if(!element.parentElement) return null

    if(element.parentElement.classList.contains(className)) {
        return element.parentElement
    } else {
        return recursiveFindParentWithClassName(element.parentElement, className)
    }
}

export function galleryPath(fileName) {
    return `${process.env.VUE_APP_API_URL}/storage/photo_gallery/${fileName}`
}

export function prepareMultipardFormData(payload) {
    let formData = new FormData()
    for (const [key, value] of Object.entries(payload)) {
        
        if (typeof value === "object") {
            for (let item in value) {
                formData.append(key + "[]", value[item])
            }
        } else {
            formData.append(key, value)
        }
        
    }
    return formData
}